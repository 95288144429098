import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["aria-controls", "data-bs-target", "aria-expanded"]
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "card-body border-top p-5 p-md-9" }
const _hoisted_5 = ["id"]
const _hoisted_6 = ["id", "aria-controls", "data-bs-target", "aria-selected"]
const _hoisted_7 = { class: "d-md-block fs-4 text-primary ps-3" }
const _hoisted_8 = ["id"]
const _hoisted_9 = ["id", "aria-labelledby"]
const _hoisted_10 = { class: "card mb-5 mb-xl-10" }
const _hoisted_11 = { class: "card-header card-header-jaya border-primary" }
const _hoisted_12 = { class: "card-body border-top p-5 p-md-9" }
const _hoisted_13 = { class: "w-100 d-flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_card_title_jaya = _resolveComponent("card-title-jaya")!
  const _component_EcosystemicServiceInfo = _resolveComponent("EcosystemicServiceInfo")!
  const _component_VideoComponent = _resolveComponent("VideoComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ecosystemicServicesCategories, (ecosystemicServicesCategory) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "card mb-5 mb-xl-10",
        key: 'ecosystemicServicesCategory' + ecosystemicServicesCategory.id,
        id: 'category' + ecosystemicServicesCategory.id.toString()
      }, [
        _createElementVNode("div", {
          class: "card-header card-header-jaya border-primary",
          "aria-controls": 
        'kt_ecosystemic_services_category_' + ecosystemicServicesCategory.id
      ,
          "data-bs-target": 
        '#kt_ecosystemic_services_category_' + ecosystemicServicesCategory.id
      ,
          "aria-expanded": _ctx.activeCategoryId === ecosystemicServicesCategory.id,
          "data-bs-toggle": "collapse",
          role: "button"
        }, [
          _createVNode(_component_card_title_jaya, {
            "duotune-icon": "technology/teh010",
            title: 'Catégorie \'' + ecosystemicServicesCategory.name + '\''
          }, null, 8, ["title"])
        ], 8, _hoisted_2),
        _createElementVNode("div", {
          id: 'kt_ecosystemic_services_category_' + ecosystemicServicesCategory.id,
          class: "collapse"
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("ul", {
              id: 'pills-tab_' + ecosystemicServicesCategory.id,
              class: "nav nav-tabs nav-fill mb-3",
              role: "tablist"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ecosystemicServicesCategory.services, (ecosystemicService, idES) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: "nav-item",
                  role: "presentation",
                  key: 'ecosystemicService' + ecosystemicService.id
                }, [
                  _createElementVNode("button", {
                    id: 'pills-ecosystemic-service-tab_' + ecosystemicService.id,
                    "aria-controls": 
                'pills-ecosystemic-service_' + ecosystemicService.id
              ,
                    "data-bs-target": 
                '#pills-ecosystemic-service_' + ecosystemicService.id
              ,
                    "aria-selected": 
                _ctx.activeCategoryId === ecosystemicServicesCategory.id
                  ? _ctx.activeServiceId === ecosystemicService.id
                  : idES === 0
              ,
                    class: _normalizeClass(["nav-link rounded-0 rounded-top d-flex align-items-center justify-content-center", 
                _ctx.activeCategoryId === ecosystemicServicesCategory.id
                  ? _ctx.activeServiceId === ecosystemicService.id
                    ? 'active'
                    : ''
                  : idES === 0
                  ? 'active'
                  : ''
              ]),
                    "data-bs-toggle": "pill",
                    role: "tab",
                    type: "button"
                  }, [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(ecosystemicService.short_name), 1)
                  ], 10, _hoisted_6)
                ]))
              }), 128))
            ], 8, _hoisted_5),
            _createElementVNode("div", {
              id: 'pills_' + ecosystemicServicesCategory.id,
              class: "tab-content"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ecosystemicServicesCategory.services, (ecosystemicService, idES) => {
                return (_openBlock(), _createElementBlock("div", {
                  id: 'pills-ecosystemic-service_' + ecosystemicService.id,
                  "aria-labelledby": 
              'pills-ecosystem-service-tab_' + ecosystemicService.id
            ,
                  class: _normalizeClass(["tab-pane fade pt-2", 
              _ctx.activeCategoryId === ecosystemicServicesCategory.id
                ? _ctx.activeServiceId === ecosystemicService.id
                  ? 'show active'
                  : ''
                : idES === 0
                ? 'show active'
                : ''
            ]),
                  role: "tabpanel",
                  key: 'ecosystemicServicePanel' + ecosystemicService.id
                }, [
                  _createVNode(_component_EcosystemicServiceInfo, { ecosystemicService: ecosystemicService }, null, 8, ["ecosystemicService"])
                ], 10, _hoisted_9))
              }), 128))
            ], 8, _hoisted_8)
          ])
        ], 8, _hoisted_3)
      ], 8, _hoisted_1))
    }), 128)),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_card_title_jaya, {
          "duotune-icon": "social/soc007",
          title: "Vidéo de présentation"
        })
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          (_ctx.videoES.url)
            ? (_openBlock(), _createBlock(_component_VideoComponent, {
                key: 0,
                class: "mw-500px",
                "video-url": _ctx.videoES.url,
                onlineVideoUrl: _ctx.videoES.online_url,
                style: {"height":"300px"}
              }, null, 8, ["video-url", "onlineVideoUrl"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 64))
}