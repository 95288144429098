
import { defineComponent, onMounted, ref } from "vue";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { EcosystemicServicesCategory } from "@/store/modules/DiagnosticsModule";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EcosystemicServiceInfo from "@/components/diagnostic/EcosystemicServiceInfo.vue";
import VideoComponent from "@/components/global/VideoComponent.vue";
import { videoObject } from "@/store/modules/VideosModule";

export default defineComponent({
  name: "EcosystemicServices",
  components: {
    VideoComponent,
    CardTitleJaya,
    EcosystemicServiceInfo,
  },
  props: {
    serviceId: { type: String, required: false },
  },
  setup(props) {
    const store = useStore();

    let ecosystemicServicesCategories = ref<Array<EcosystemicServicesCategory>>(
      []
    );

    let activeServiceId = ref<number | undefined>(-1);
    let activeCategoryId = ref<number | undefined>(-1);
    let videoES = ref<videoObject>({
      url: "",
      online_url: "",
      description: "",
    });
    onMounted(() => {
      store.dispatch(Actions.FETCH_MODULE_VIDEOS).then(() => {
        if (
          store.getters.getModuleVideos.some((e) => {
            return e.module_name === 1;
          })
        ) {
          videoES.value = store.getters.getModuleVideos.find((e) => {
            return e.module_name === 1;
          }).video;
        }
      });
      store.dispatch(Actions.FETCH_ECOSYSTEMIC_SERVICES_CATEGORIES).then(() => {
        setCurrentPageTitle("Services Ecosystémiques");
        ecosystemicServicesCategories.value =
          store.getters.getEcosystemicServicesCategories;

        activeServiceId.value = props.serviceId
          ? Number(props.serviceId)
          : ecosystemicServicesCategories.value[0].services[0].id;

        activeCategoryId.value = props.serviceId
          ? ecosystemicServicesCategories.value.find((esc) => {
              return esc.services
                .map((s) => s.id)
                .includes(Number(props.serviceId));
            })?.id
          : ecosystemicServicesCategories.value[0].id;
      });
    });

    return {
      ecosystemicServicesCategories,
      activeCategoryId,
      activeServiceId,
      videoES,
    };
  },
});
