<template>
  <h3 v-if="!resume" class="text-primary fw-boldest text-center">
    {{ ecosystemicService.name }}
    <span class="fst-italic fw-normal"
      >({{ ecosystemicService.short_name }})</span
    >
  </h3>
  <div class="m-3">
    <h4 class="fs-4 fw-bold">Description</h4>
    <p class="text-gray-600">{{ ecosystemicService.description }}</p>
  </div>
  <div class="m-3">
    <h4 class="fs-4 fw-bold">
      Intérêt
      <a v-if="!resume" @click="fireSwalExplanation('interest')">
        <i class="bi text-secondary bi-question-circle fs-2" />
      </a>
    </h4>
    <p class="text-gray-600">{{ ecosystemicService.interest }}</p>
  </div>
  <div class="m-3">
    <h4 class="fs-4 fw-bold">
      Représentativité
      <a v-if="!resume" @click="fireSwalExplanation('representativeness')">
        <i class="bi text-secondary bi-question-circle fs-2" />
      </a>
    </h4>
    <p class="text-gray-600">
      {{ ecosystemicService.representativeness }}
    </p>
  </div>
  <div v-if="!resume" class="m-3">
    <h4 class="fs-4 fw-bold">
      Pratiques non estimées
      <a @click="fireSwalExplanation('non_estimated_practices')">
        <i class="bi text-secondary bi-question-circle fs-2" />
      </a>
    </h4>
    <ul class="text-gray-600">
      <li
        v-for="(practice, id) in ecosystemicService.not_estimated_practices"
        :key="
          'NotEstimatedPractice' + ecosystemicService.id + '_' + id.toString()
        "
      >
        <span>{{ practice[0] }}</span> :
        <span class="fw-boldest">{{ practice[1] }}</span>
      </li>
    </ul>
  </div>
  <div v-if="!resume" class="m-3">
    <h4 class="fs-4 fw-bold">Ressources complémentaires</h4>
    <ul class="text-gray-600">
      <li
        v-for="(resource, id) in ecosystemicService.additional_resources"
        :key="
          'AdditionalResource' + ecosystemicService.id + '_' + id.toString()
        "
      >
        {{ resource[0] }}
        <a
          @click="openLinkAgriBest(resource[1])"
          class="cursor-pointer text-primary"
        >
          <i class="bi bi-box-arrow-up-right text-primary fs-3" />
        </a>
      </li>
    </ul>
  </div>
  <div v-if="!resume" class="my-3">
    <h4 class="fs-4 fw-bold mx-3">Influence des facteurs du questionnaire</h4>
    <ecosystemic-services-factors-justifications
      :factors="factors"
      :ecosystemic-services="[ecosystemicService]"
    />
  </div>
</template>

<script>
import { openLinkAgriBest } from "@/core/helpers/globalJaya";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { computed } from "vue";
import EcosystemicServicesFactorsJustifications from "@/components/diagnostic/EcosystemicServicesFactorsJustifications.vue";

export default {
  name: "EcosystemicServiceInfo",
  components: { EcosystemicServicesFactorsJustifications },
  props: {
    ecosystemicService: Object,
    resume: { type: Boolean, default: false },
  },
  setup() {
    const store = useStore();
    const fireSwalExplanation = (key) => {
      if (store.getters.getEcosystemicServiceFieldExplanation[key]) {
        const title =
          store.getters.getEcosystemicServiceFieldExplanation[key][0];
        const explanation =
          store.getters.getEcosystemicServiceFieldExplanation[key][1];

        Swal.fire({
          title: title,
          html: explanation,
          icon: "info",
          buttonsStyling: false,
          confirmButtonText: "J'ai compris",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        });
      }
    };

    const factors = computed(() => store.getters.getFactors);

    return {
      fireSwalExplanation,
      openLinkAgriBest: openLinkAgriBest,
      factors,
    };
  },
};
</script>
